import classnames from 'classnames'
import { ForwardedRef, HTMLProps, PropsWithChildren, ReactNode, forwardRef } from 'react'

import { Icon } from './icon'

export enum ColorVariant {
  violet = 'violet',
  emerald = 'emerald',
  orange = 'orange',
  teal = 'teal',
  pink = 'pink',
  lime = 'lime',
  purple = 'purple',
  green = 'green',
  yellow = 'yellow',
  blue = 'blue',
  red = 'red',
  gray = 'gray',
  default = 'default',
  darkGray = 'darkGray',
}

export enum CheckboxSize {
  none = '', // Do not set fixed size
  small = 'min-h-6 text-2xs', // 24px
  regular = 'min-h-7 text-sm', // 28px
}
export type CheckboxSizeType = keyof typeof CheckboxSize

interface CheckboxProps extends Omit<HTMLProps<HTMLInputElement>, 'label' | 'size'> {
  /**
   * Text that gets displayed on the right side of the checkbox
   */
  label?: ReactNode

  checked: boolean

  /**
   * Size of the checkbox
   *
   * @default "regular"
   */
  size?: CheckboxSizeType

  /**
   * Color of the checkbox
   *
   * @default "default"
   */
  color?: ColorVariant
}

function CheckboxComponent(
  {
    className,
    label,
    size = 'regular',
    color = ColorVariant.default,
    children,
    ...props
  }: PropsWithChildren<CheckboxProps>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const checked = props.checked ?? props.defaultChecked

  return (
    <label
      className={classnames(className, CheckboxSize[size], 'group flex items-center', {
        'text-gray-400 dark:text-gray-500': props.disabled,
      })}
    >
      <div className="relative mr-2 flex">
        <input
          {...props}
          ref={ref}
          type="checkbox"
          className={classnames(
            'h-4 w-4 cursor-pointer appearance-none rounded-sm border ring-gray-300 focus:outline-none focus-visible:ring-2', // Base
            'border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-800', // Normal
            'group-hover:border-gray-400', // Hover
            'disabled:border-gray-100 disabled:bg-gray-100 disabled:checked:border-gray-400 disabled:checked:bg-gray-400 dark:disabled:bg-gray-600 dark:disabled:checked:border-gray-600 dark:disabled:checked:bg-gray-600 dark:disabled:group-hover:border-gray-600', // Disabled
            // Checked color styles
            {
              'checked:border-violet-400 checked:bg-violet-400 group-hover:checked:border-violet-500 group-hover:checked:bg-violet-500 dark:checked:border-violet-400 dark:checked:bg-violet-400 dark:group-hover:checked:border-violet-300 dark:group-hover:checked:bg-violet-300':
                color === ColorVariant.violet,
              'checked:border-emerald-400 checked:bg-emerald-400 group-hover:checked:border-emerald-500 group-hover:checked:bg-emerald-500 dark:checked:border-emerald-300 dark:checked:bg-emerald-300 dark:group-hover:checked:border-emerald-200 dark:group-hover:checked:bg-emerald-200':
                color === ColorVariant.emerald,
              'checked:border-orange-300 checked:bg-orange-300 group-hover:checked:border-orange-400 group-hover:checked:bg-orange-400 dark:checked:border-orange-300 dark:checked:bg-orange-300 dark:group-hover:checked:border-orange-200 dark:group-hover:checked:bg-orange-200':
                color === ColorVariant.orange,
              'checked:border-teal-400 checked:bg-teal-400 group-hover:checked:border-teal-500 group-hover:checked:bg-teal-500 dark:checked:border-teal-300 dark:checked:bg-teal-300 dark:group-hover:checked:border-teal-200 dark:group-hover:checked:bg-teal-200':
                color === ColorVariant.teal,
              'checked:border-pink-400 checked:bg-pink-400 group-hover:checked:border-pink-500 group-hover:checked:bg-pink-500 dark:checked:border-pink-400 dark:checked:bg-pink-400 dark:group-hover:checked:border-pink-300 dark:group-hover:checked:bg-pink-300':
                color === ColorVariant.pink,
              'checked:border-lime-400 checked:bg-lime-400 group-hover:checked:border-lime-500 group-hover:checked:bg-lime-500 dark:checked:border-lime-300 dark:checked:bg-lime-300 dark:group-hover:checked:border-lime-200 dark:group-hover:checked:bg-lime-200':
                color === ColorVariant.lime,
              'checked:border-purple-400 checked:bg-purple-400 group-hover:checked:border-purple-500 group-hover:checked:bg-purple-500 dark:checked:border-purple-400 dark:checked:bg-purple-400 dark:group-hover:checked:border-purple-300 dark:group-hover:checked:bg-purple-300':
                color === ColorVariant.purple,
              'checked:border-green-400 checked:bg-green-400 group-hover:checked:border-green-500 group-hover:checked:bg-green-500 dark:checked:border-green-400 dark:checked:bg-green-400 dark:group-hover:checked:border-green-300 dark:group-hover:checked:bg-green-300':
                color === ColorVariant.green,
              'checked:border-yellow-300 checked:bg-yellow-300 group-hover:checked:border-yellow-400 group-hover:checked:bg-yellow-400 dark:checked:border-yellow-200 dark:checked:bg-yellow-200 dark:group-hover:checked:border-yellow-100 dark:group-hover:checked:bg-yellow-100':
                color === ColorVariant.yellow,
              'checked:border-blue-400 checked:bg-blue-400 group-hover:checked:border-blue-500 group-hover:checked:bg-blue-500 dark:checked:border-blue-300 dark:checked:bg-blue-300 dark:group-hover:checked:border-blue-200 dark:group-hover:checked:bg-blue-200':
                color === ColorVariant.blue,
              'checked:border-red-400 checked:bg-red-400 group-hover:checked:border-red-500 group-hover:checked:bg-red-500 dark:checked:border-red-400 dark:checked:bg-red-400 dark:group-hover:checked:border-red-300 dark:group-hover:checked:bg-red-300':
                color === ColorVariant.red,
              'checked:border-gray-400 checked:bg-gray-400 group-hover:checked:border-gray-500 group-hover:checked:bg-gray-500 dark:checked:border-gray-400 dark:checked:bg-gray-400 dark:group-hover:checked:border-gray-300 dark:group-hover:checked:bg-gray-300':
                color === ColorVariant.gray,
              'checked:border-gray-700 checked:bg-gray-700 group-hover:checked:border-gray-800 group-hover:checked:bg-gray-800 dark:checked:border-gray-200 dark:checked:bg-gray-200 dark:group-hover:checked:border-gray-100 dark:group-hover:checked:bg-gray-100':
                color === ColorVariant.darkGray,
              'checked:border-blue-400 checked:bg-blue-400 group-hover:checked:border-blue-500 group-hover:checked:bg-blue-500 dark:checked:border-blue-400 dark:checked:bg-blue-400 dark:group-hover:checked:border-blue-500':
                color === ColorVariant.default,
            }
          )}
        />
        {checked && (
          <Icon
            name="done"
            className={classnames('absolute left-0.5 top-0.5', {
              'text-gray-50': color === ColorVariant.default,
              'text-white dark:text-gray-800': color !== ColorVariant.default,
            })}
            width={12}
          />
        )}
      </div>
      {label ?? children}
    </label>
  )
}

export const Checkbox = forwardRef(CheckboxComponent)
