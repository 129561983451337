import classNames from 'classnames'
import React from 'react'

enum BadgeSize {
  small = 'h-4 min-w-4', // 16px height
  medium = 'h-5 min-w-5', // 20px height
}
type BadgeSizeType = keyof typeof BadgeSize

enum BadgePadding {
  small = 'px-2 py-0', // 16px height
  medium = 'px-1.5 py-0.5', // 20px height
}
type BadgePaddingType = keyof typeof BadgePadding

type BadgeProps = {
  className?: string
  size?: BadgeSizeType
  padding?: BadgePaddingType
  active?: boolean
}

export function Badge({
  className,
  size = 'medium',
  padding = size,
  active,
  children,
}: React.PropsWithChildren<BadgeProps>) {
  const badgePadding = BadgePadding[padding]
  const badgeSize = BadgeSize[size]

  return (
    <span
      className={classNames(
        className,
        'inline-flex justify-center rounded-full text-2xs leading-4 font-medium',
        badgePadding,
        badgeSize,
        {
          'bg-blue-500 text-blue-50 dark:text-white': active,
          'bg-gray-200/30 text-default group-hover/badge:bg-gray-200/50 hover:bg-gray-200/50 dark:bg-gray-500/30 dark:group-hover/badge:bg-gray-200/50 dark:hover:bg-gray-200/50':
            !active,
        }
      )}
    >
      {children}
    </span>
  )
}
